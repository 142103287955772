import * as React from 'react';

import { determineRowColor, getBlockingExternalSubmissionUrl } from '../utils/row-helpers';
import { DISPLAY_DATE_TIME_AM_PM_S, DEFAULT_DATETIME_FORMAT, DISPLAY_DATE_FORMAT } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { EXTERNAL_SOURCES, UNKNOWN, CLEARANCE_STATUS, ARCH_CONFLICT_ERRORS, INBOX_IDS } from '../constants/Constants';
import { getAccountNumberFromString, getDomainIdForEnv } from '@archinsurance-viki/property-jslib/src/utils/arch-helpers';
import { GridCheckMark } from '../components/misc/GridCheckMark';
import moment from 'moment';
import { ColumnType, ExtraDataType, TableGlossaryType, TableRowDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { isNullish } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import { SubmissionType } from '../ts-types/DataTypes';
import { GridBadge, GridBadgeType } from '../components/misc/GridBadge';
import _ from 'lodash';

const SELECT_COL_PROPS: ColumnType = {
    fixed_w: 100,
    field_name: 'select',
    name: '',
    cellType: 'actions',
    actions: [
        {
            text: (rowData: any, { currentSubmission, field_name }: any = {}) => {
                if (currentSubmission && currentSubmission[field_name] === rowData.id) {
                    return <GridCheckMark />;
                }
                return '';
            },
            actionFn: 'onSelectNewMatch',
        },
    ],
    active: true,
};

const SELECT_OFFICE_COL_PROPS: ColumnType = {
    fixed_w: 100,
    field_name: 'select',
    name: '',
    cellType: 'actions',
    actions: [
        {
            text: (rowData: any, { currentSubmission, _field_name }: any = {}) => {
                if (currentSubmission && currentSubmission.office_id === rowData.id) {
                    return <GridCheckMark />;
                }
                return '';
            },
            actionFn: 'onSelectNewMatch',
        },
    ],
    active: true,
};

const SELECT_ARCH_PRODUCER_COL_PROPS: ColumnType = {
    fixed_w: 100,
    field_name: 'select',
    name: '',
    cellType: 'actions',
    actions: [
        {
            text: (rowData: any, { currentSubmission, _field_name }: any = {}) => {
                if (currentSubmission && rowData.arch_id && `${currentSubmission.office_arch_id}` === `${rowData.arch_id}`) {
                    return <GridCheckMark />;
                }
                return '';
            },
            actionFn: 'onSelectNewMatch',
        },
    ],
    active: true,
};

const SELECT_ARCH_COL_PROPS = {
    fixed_w: 100,
    field_name: 'select',
    name: '',
    cellType: 'actions',
    actions: [
        {
            text: (rowData: any, { currentSubmission }: any = {}) => {
                if (rowData.selected) {
                    return <GridCheckMark />;
                }
                return currentSubmission && rowData.id === currentSubmission.arch_account.is_suspended ? 'CREATE NEW' : '';
            },
            actionFn: 'onSelectNewMatch',
        },
    ],
};

const SELECT_ARCH_ACCOUNT_COL_PROPS = {
    fixed_w: 100,
    field_name: 'select',
    name: '',
    cellType: 'actions',
    actions: [
        {
            text: (rowData: any, { currentSubmission, field_name }: any = {}) => {
                return currentSubmission && currentSubmission[field_name] === rowData.arch_id ? <GridCheckMark /> : '';
            },
            actionFn: 'onSelectNewMatch',
        },
    ],
};

const ID_COL_PROPS = {
    w: 100,
    field_name: 'id',
    name: 'ID',
    readonly: true,
    field_format: { nocommas: true },
    classNameFn: (rowData: any) => determineRowColor(rowData),
    show_others_viewing_icon: true,
};

const ID_COLOR_HELPER = {
    classNameFn: (rowData: any) => determineRowColor(rowData),
};

const INSURED_READONLY = (rowData: any, extraData: any = {}) => {
    if (!rowData.is_editable) {
        return true;
    }

    if (rowData.arch_account_arch_id) {
        return !extraData.insuredEditableOverride;
    }

    return false;
};

const INSURED_READONLY_CLIENT_MATCHING = (rowData: any, extraData: any = {}) => {
    const currentSubmission = extraData.currentSubmission;
    return !currentSubmission.is_editable || !currentSubmission.arch_account_id;
};

const SUBMISSION_READONLY = (rowData: any, extraData: Record<string, any>) => {
    const { me } = extraData;
    const userPermittedToEdit = me && (!rowData.assigned_to_id || rowData.assigned_to_id === me.id);
    return !rowData.is_editable || !userPermittedToEdit;
};

const READONLY_DUE_TO_SMMS_SUBMISSION = (rowData: any, extraData: Record<string, any>) => {
    const isSMMSSubmission = rowData.inbox_id === INBOX_IDS.SMMS;
    return isSMMSSubmission || SUBMISSION_READONLY(rowData, extraData);
};

const READONLY_DUE_TO_LINKED_RENEWAL_SUBMISSION = (rowData: any, extraData: Record<string, any>) => {
    return SUBMISSION_READONLY(rowData, extraData) || rowData.is_renewal_linked;
};

const READONLY_UWPF_DUE_TO_RETAIL_OR_SMMS = (rowData: any, extraData: Record<string, any>) => {
    const isRetailSubmission = rowData.inbox_id === extraData.CONSTANTS?.INBOX_IDS.RETAIL;
    const isSMMSSubmission = rowData.inbox_id === extraData.CONSTANTS?.INBOX_IDS.SMMS;
    return SUBMISSION_READONLY(rowData, extraData) || isRetailSubmission || isSMMSSubmission;
};

const REPLY_TO_READONLY = (rowData: any) => {
    return rowData.reply_to_email_disabled;
};

const DAYS_FROM_RECEIPT = 120;

const STREET = {
    w: 250,
    field_name: 'street',
    short_name: 'Street',
};

const CITY = {
    w: 100,
    field_name: 'city',
    short_name: 'City',
};

const STATE = {
    w: 40,
    field_name: 'state',
    short_name: 'ST',
};

const ZIPCODE = {
    w: 55,
    field_name: 'zipcode',
    short_name: 'ZIP',
};

const FEIN = {
    w: 90,
    field_name: 'fein',
    short_name: 'Fein',
};

const IMPORT_OBJECT_ID = {
    w: 80,
    field_name: 'import_object_id',
    short_name: 'VIKI ID',
};

// relevant subdivisions to display in producer / contact search UI
const RELEVANT_SUBDIVISIONS = ['5010', '5011', '5020', '6312'];

function filterRelevantSubdivisions(rawSubdivisions: Record<string, any>) {
    if (_.isEmpty(rawSubdivisions) || _.isNull(rawSubdivisions)) {
        return '';
    }

    let filteredSubdivisions = rawSubdivisions.split(',').filter(subdiv => {
        return RELEVANT_SUBDIVISIONS.indexOf(subdiv) !== -1;
    });

    return filteredSubdivisions.join(', ');
}

const style = { display: 'inline' };
export const conflictBadges = {
    likelyBlocked: {
        description: <span style={style}>Likely Blocked</span>,
        badge: (
            <div style={style} className="badge badge-blocked w-fit margin-left-05" title="Likely blocked">
                B
            </div>
        ),
    },
    internationalSubmission: {
        description: <span style={style}>International Submission</span>,
        badge: (
            <div
                style={{ ...style, position: 'relative', top: '2px', fontSize: 'unset' }}
                className="badge badge-info w-fit material-icons margin-left-05"
                title="International"
            >
                flight
            </div>
        ),
    },
    possibleDuplicate: {
        description: <span style={style}>Possible Duplicate</span>,
        badge: (
            <div style={style} className="badge badge-warning w-fit margin-left-05" title="Possible Duplicate">
                PD
            </div>
        ),
    },
    submissionConflict: {
        description: <span style={style}>Submission Conflict</span>,
        badge: (
            <div style={style} className="badge badge-grey w-fit margin-left-05" title="Submission Conflict">
                SC
            </div>
        ),
    },
    renewal: {
        description: <span style={style}>Renewal</span>,
        badge: (
            <div style={style} className="badge badge-success w-fit margin-left-05" title="Renewal">
                RE
            </div>
        ),
    },
};

function getConflictType(value: any, rowData: any) {
    let badges = [];
    if (rowData.conflict === 'possibleDuplicate') {
        badges.push(
            <div style={style} className="badge badge-warning w-fit" title="Possible Duplicate">
                PD
            </div>
        );
    } else if (rowData.conflict === 'submissionConflict') {
        badges.push(
            <div style={style} className="badge badge-grey w-fit" title="Submission Conflict">
                SC
            </div>
        );
    }

    if (rowData.is_renewal) {
        badges.push(conflictBadges.renewal.badge);
    }
    return badges;
}

const getAlternateNameActionBadges = (_value: any, rowData: any) => {
    let title = 'NONE';
    let color = 'grey';
    switch (rowData.action) {
        case 'Add':
            title = 'ADD';
            color = 'info';
            break;
        case 'Delete':
            title = 'DELETE';
            color = 'blocked';
            break;
        case 'Edit':
            title = 'EDIT';
            color = 'success';
            break;
        case 'Pending':
            title = 'PENDING';
            color = 'warning';
            break;
        case 'Ofac':
            title = 'OFAC';
            color = 'clear';
            break;
        case 'Abort':
            title = 'ABORT';
            color = 'blocked';
            break;
        case 'Error':
            title = 'ERROR';
            color = 'archived';
            break;
        case 'Conflict':
            title = 'CONFLICT';
            color = 'archived';
            break;
        default:
            title = 'NONE';
            color = 'grey';
            break;
    }
    return (
        <div style={style} key="altName-action-indicator" className={`badge badge-${color} w-fit margin-right-05`} title={title}>
            <span className="tw-font-semibold">{title}</span>
        </div>
    );
};

function extraIdContent(value: any, rowData: any, _col: ColumnType, onClickEvents?: Record<string, any>, _extraData?: any) {
    // VIKI-extraIdContent
    let badges = [];

    const hasValidations = rowData._validations && !_.isEmpty(rowData._validations.WARN);
    const isBlocked = rowData.status === CLEARANCE_STATUS.BLOCKED;
    const isArchieved = rowData.status === CLEARANCE_STATUS.ARCHIVED;
    const isDeclined = rowData.status === CLEARANCE_STATUS.DECLINED;
    const isArchSubmissionConflict =
        hasValidations && rowData._validations.WARN.id && rowData._validations.WARN.id.join(' ').includes(ARCH_CONFLICT_ERRORS.SUBMISSION_CONFLICT);

    if (rowData.is_likely_blocked) {
        badges.push(
            <div key="is-likely-blocked" className="badge badge-blocked margin-left-05" title="Likely blocked">
                B
            </div>
        );
    } else if (!hasValidations && !rowData.date_cleared && !(isBlocked || isArchieved || isDeclined)) {
        badges.push(
            <div key="is-ready-for-clear" className="badge badge-clear margin-left-05" title="Eligible to clear">
                C
            </div>
        );
    }

    if (isArchSubmissionConflict) {
        const handleClick = () => {
            onClickEvents && onClickEvents['is-arch-submission-conflict-error'](value, rowData);
        };

        badges.push(
            <div onClick={handleClick} key="is-arch-submission-conflict-error" className="badge badge-grey margin-left-05" title="Arch Submission Conflict">
                SC
            </div>
        );
    }

    return badges;
}

function extraStatusContent(value: any, rowData: any, _colId: any, onClickEvents?: Record<string, any>, extraData?: any) {
    if (rowData.source === EXTERNAL_SOURCES.VIKI || rowData.source === EXTERNAL_SOURCES.ARCH) {
        // VIKI-8224 : if this is an external submission then return no badges
        return [];
    }

    let badges = [];
    const FEATURE_FLAGS = extraData ? extraData.FEATURE_FLAGS : null;
    if (rowData.external_last_status_change && !rowData.has_new_message && !rowData.is_archived) {
        badges.push(
            <div key="message-set-externally" className="badge badge-success margin-left-05" title="Status set externally">
                E
            </div>
        );
    }

    if (rowData.has_new_message) {
        badges.push(
            <div
                key="has-new-message"
                className="badge badge-icon badge-success margin-left-05"
                title="New Message"
                style={{ lineHeight: '11px', fontSize: '22px' }}
            >
                <i className="material-icons md-15">email</i>
            </div>
        );
    }
    if (rowData.is_archived) {
        badges.push(
            <div key="is-archieved" className="badge badge-archived margin-left-05" title="Archived">
                A
            </div>
        );
    }

    if (rowData.is_deleted) {
        badges.push(
            <div key="is-deleted" className="badge badge-grey margin-left-05" title="Deleted">
                D
            </div>
        );
    }

    // TODO, VIKI-7735 make sure feature flag needs_reconciliation is turned on when this is commented in
    const actionFailedDueToConflict = rowData.arch_clearance_api_status === 'ACCOUNT_CONFLICT';
    if (rowData.last_user_action_failed || actionFailedDueToConflict) {
        const isPendingSubmissionClear = rowData.arch_clearance_api_status === 'PENDING_SUBMISSION_CLEAR' || rowData.arch_clearance_api_status === 'PROCESSING';

        const lastFailedUserAction = { [CLEARANCE_STATUS.CLEARED]: 'clear', [CLEARANCE_STATUS.BLOCKED]: 'block', [CLEARANCE_STATUS.DECLINED]: 'decline' }[
            rowData.last_user_action_failed_action_type
        ];

        const badgeColor = actionFailedDueToConflict || isPendingSubmissionClear ? 'orange' : 'red';
        const failureMessage = actionFailedDueToConflict ? 'Conflict detected.' : `Last ${lastFailedUserAction} attempt failed.`;

        badges.push(
            <div key="is-deleted" className={`material-icons ${badgeColor}`} title={failureMessage}>
                report
            </div>
        );
    }

    if (FEATURE_FLAGS && rowData.last_user_response_email_failed && FEATURE_FLAGS.email_failure_indicator_enabled) {
        badges.push(
            <div key="failed-to-send-last-email" className="material-icons red" title="Failed to send last response email">
                forward_to_inbox
            </div>
        );
    }

    if (rowData.suggested_auto_clear || rowData.suggested_auto_decline || rowData.suggested_auto_block) {
        let handleClick;

        if (rowData.unactioned_auto_suggested_action && extraData?.currentTab?.id === 'pending_auto_actions') {
            handleClick = e => {
                onClickEvents && onClickEvents['handle-override-auto-action'](e);
            };
        }

        if (rowData.suggested_auto_decline) {
            badges.push(
                <div onClick={handleClick} className="material-icons orange auto-badges" title="Suggesting Auto Decline">
                    credit_card_off
                </div>
            );
        }
        if (rowData.suggested_auto_block) {
            badges.push(
                <div onClick={handleClick} className="material-icons pink auto-badges" title="Suggesting Auto Block">
                    work_off
                </div>
            );
        }
        if (rowData.suggested_auto_clear) {
            badges.push(
                <div
                    onClick={handleClick}
                    className="material-icons blue auto-badges"
                    title={`Suggesting Auto ${rowData.is_renewal_linked ? 'Renewal' : 'Clear'}`}
                >
                    verified
                </div>
            );
        }
    }

    if (rowData.is_renewal_linked) {
        badges.push(<GridBadge badgeType={GridBadgeType.RENEWAL} />);
    }

    return badges;
}

function extraDuplicateIdContent(value: any, rowData: any, _colId: any) {
    let idVal, colorIndicator, title;
    if (rowData.source === EXTERNAL_SOURCES.VIKI) {
        idVal = `V${rowData.external_id}`;
        colorIndicator = 'info';
        title = 'VIKI';
    } else if (rowData.source === EXTERNAL_SOURCES.ARCH) {
        idVal = `S${rowData.external_id}`;
        colorIndicator = 'grey';
        title = 'Arch';
    } else {
        idVal = rowData.id;
        colorIndicator = 'purple';
        title = 'VMAC';
    }
    return (
        <div key="id-indicator" className={`badge badge-${colorIndicator} w-pre-labels margin-left-05`} title={title}>
            <span>{title}</span> <span>|</span> {idVal}
        </div>
    );
}

const pendingBorExtraDuplicateContent = (value: any, rowData: any, _colId: any) => {
    let colorIndicator, title;
    let val = rowData.id;

    const isRenewalRow = _colId.id === 'rno';

    if (isRenewalRow) {
        if (rowData.renewal_of) {
            if (rowData.renewal_of.startsWith('S')) {
                val = rowData.renewal_of;
            } else {
                val = `V${rowData.renewal_of}`;
            }
        } else {
            val = null;
        }
    }

    if (val !== null) {
        if (rowData.source === EXTERNAL_SOURCES.ARCH) {
            colorIndicator = 'grey';
            title = 'Arch';
        } else if (rowData.source === EXTERNAL_SOURCES.VIKI) {
            colorIndicator = 'info';
            title = 'VIKI';
        } else {
            colorIndicator = 'purple';
            title = 'VMAC';
        }
        return (
            <div key="id-indicator" className={`badge badge-${colorIndicator} w-pre-labels margin-left-05`} title={title}>
                <span>{title}</span> <span>|</span> {val}
            </div>
        );
    }
};

const extraSubmissionConflictContent = (_value: any, rowData: any, _col: ColumnType) => {
    let badges = [];
    if (rowData.is_intl_conflict) {
        badges.push(
            <span title="International Conflict" className="material-icons international-conflict">
                flight
            </span>
        );
    }
    return badges;
};

const setBadgesOnCoflictTableSubmissionId = (rowData: any) => {
    let colorIndicator, title;
    if (rowData.submission_id !== null) {
        if (rowData.source === EXTERNAL_SOURCES.ARCH) {
            colorIndicator = 'grey';
            title = 'Arch';
        } else if (rowData.source === EXTERNAL_SOURCES.VIKI) {
            colorIndicator = 'info';
            title = 'VIKI';
        } else {
            colorIndicator = 'purple';
            title = 'VMAC';
        }
        return (
            <div key="id-indicator" className={`badge badge-${colorIndicator} w-pre-labels margin-left-05`} title={title}>
                <span>{title}</span> <span>|</span> {rowData.submission_id}
            </div>
        );
    }
    return '';
};

export const COMBINED_TABLE_GLOSSARY: Record<string, TableGlossaryType> = {
    submission: {
        i: { ...ID_COL_PROPS, ...ID_COLOR_HELPER, extraContent: extraIdContent },
        dti: { w: 60, name: 'Days Till Inception' },
        archaname: {
            w: 250,
            readonly: INSURED_READONLY,
            name: 'Insured Name (ArchLink)',
        },
        po: {
            w: 130,
            readonly: SUBMISSION_READONLY,
        },
        iinception: {
            name: 'Inception Date',
            readonly: SUBMISSION_READONLY,
            openToDate: (props: any) => {
                let { inception_date, date_received } = props.currentSubmission;
                return inception_date ? inception_date : moment(date_received).add(DAYS_FROM_RECEIPT, 'days').format('YYYY-MM-DD');
            },
        },
        iexpiration: {
            readonly: READONLY_DUE_TO_LINKED_RENEWAL_SUBMISSION,
        },
        s: {
            readonly: true, // keep readonly because if you change status it needs to switch tabs in the grid
            w: 115,
            extraContent: extraStatusContent,
            cellDataGetter: (value: any, rowData: any, _colId: string) => {
                if (rowData === undefined) return;
                const isIncumbentSubmission = rowData.bor_incumbent_submission_id === String(rowData.id);
                const incumbentStatus = isIncumbentSubmission ? 'I' : '';
                if (rowData.pending_bor_status === 'N' || rowData.pending_bor_status === 'F') return rowData.pending_bor_status;

                const pendingBorStatus =
                    rowData.pending_bor_status && ['P', 'L', 'N'].includes(rowData.pending_bor_status) ? `${rowData.pending_bor_status}${incumbentStatus}` : '';
                return `${pendingBorStatus}${value}`;
            },
        },
        bs: {
            readonly: SUBMISSION_READONLY,
        },
        dr: {
            w: 150,
            field_format: {
                dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S,
                dateStoreFormat: DEFAULT_DATETIME_FORMAT,
            },
        },
        hs: {
            readonly: SUBMISSION_READONLY,
        },
        rte: {
            readonly: REPLY_TO_READONLY,
        },
        pan: {
            w: 130,
            readonly: true,
        },
        pn: {
            readonly: true,
        },
        apn: {
            readonly: true,
        },
        pai: {
            readonly: true,
        },
        pppcid: {
            readonly: true,
        },
        inb: {
            readonly: READONLY_DUE_TO_LINKED_RENEWAL_SUBMISSION,
        },
        ibd: {
            readonly: SUBMISSION_READONLY,
        },
        irate: {
            readonly: SUBMISSION_READONLY,
        },
        itarget: {
            readonly: SUBMISSION_READONLY,
        },
        iname: {
            readonly: INSURED_READONLY,
        },
        istreet: {
            w: 200,
            readonly: INSURED_READONLY,
        },
        icity: {
            readonly: INSURED_READONLY,
        },
        istate: {
            readonly: INSURED_READONLY,
        },
        iz: {
            readonly: INSURED_READONLY,
        },
        if: {
            readonly: INSURED_READONLY,
        },
        igp: {
            name: 'Is Gateway?',
            readonly: (rowData: TableRowDataType, extraData: ExtraDataType) => {
                return rowData.inbox_id !== (extraData.CONSTANTS.INBOX_IDS as any).RETAIL || !rowData.is_editable;
            },
        },
        pppc: {
            readonly: true,
        },
        uwpf: {
            readonly: READONLY_UWPF_DUE_TO_RETAIL_OR_SMMS,
        },
        rui: {
            readonly: true,
        },
        arui: {
            readonly: true,
        },
        oui: {
            readonly: true,
        },
        aoui: {
            readonly: true,
        },
        fe: {
            readonly: true,
        },
        type: {
            name: 'Created',
            readonly: true,
        },
        bldgs: {
            name: 'Created',
            readonly: true,
        },
        tiv: {
            name: 'Created',
            readonly: true,
            field_format: { subtype: 'currency' },
        },
        archloc: {
            field_format: { subtype: 'badge' },
            field_name: 'arch_account_passes_ofac_checks',
            name: 'OFAC',
        },
        apoc: {
            field_format: { subtype: 'badge' },
            name: 'OFAC Check',
            cellDataGetter: (_: any, submission: any) => {
                return submission && submission.arch_account_passes_ofac_checks;
            },
        },
        inp: {
            neverEditable: true,
        },
        panp: {
            neverEditable: true,
        },
        rbonp: {
            neverEditable: true,
        },
        rpol: {
            readonly: true,
            field_name: 'office_office',
            name: 'Office Location',
        },
        rabcn: {
            readonly: READONLY_DUE_TO_SMMS_SUBMISSION,
        },
        rbon: {
            readonly: true,
            field_name: 'office_name',
            name: 'Broker Office',
        },
        pid: {
            readonly: READONLY_DUE_TO_LINKED_RENEWAL_SUBMISSION,
            constantsFilter: (choices, dataObject, selectedValue, extraData: Record<string, any>) => {
                const { CONSTANTS } = extraData;
                if (dataObject && CONSTANTS) {
                    const { PRODUCERS_BY_PRODUCING_AGENT, PRODUCER_OFFICES_ALL } = CONSTANTS;
                    const { account_agent_id, office_id, is_renewal_linked } = dataObject as SubmissionType;

                    // if renewal is not linked, return the available office dropdown as normal
                    if (!is_renewal_linked) {
                        if (isNullish(account_agent_id)) {
                            return [];
                        } else {
                            return choices;
                        }
                    }

                    // PRIN-15326 - for renewals, it is possible to only have an office set and not an account broker. In
                    // order to display the office we need to override the existing options which are normally filtered
                    // based on the account broker selection.
                    let fallBackOfficeValue = PRODUCER_OFFICES_ALL.find(c => {
                        return c[0] === office_id;
                    });

                    let fallBackOfficeList = fallBackOfficeValue === undefined ? [] : [fallBackOfficeValue];
                    if (isNullish(account_agent_id) && !isNullish(office_id)) {
                        // 1. if account_agent_id is null but office_id is set, return the office from the all office list so it displays
                        return fallBackOfficeList;
                    } else if (!isNullish(account_agent_id)) {
                        const agentOffices = PRODUCERS_BY_PRODUCING_AGENT[account_agent_id];

                        // if office_id is null return standard options which filters list by account_agent_id association
                        if (isNullish(office_id)) {
                            return agentOffices;
                        }

                        // if office_id is not null, does the office exist in the standard dropdown?
                        let value = agentOffices.find(c => {
                            return c[0] === office_id;
                        });

                        // if office does not exist in account agent association list, return it from the all office list
                        // if office does exist in account agent association list, return the account agent association list
                        return value === undefined ? fallBackOfficeList : agentOffices;
                    }
                }
                return choices;
            },
        },
        lai: {
            readonly: READONLY_DUE_TO_LINKED_RENEWAL_SUBMISSION,
            // choicesHelper: (choices: [string, string][], choicesHelperData: any) => {
            //     return choices.map((c) => {
            //         const states = choicesHelperData[c[0]];
            //         if (states && states.length) {
            //             return [c[0], `${c[1]}: [${states.join('] [')}]`];
            //         }
            //         return c;
            //     });
            // },
            // choicesHelperConstantsData: 'PRODUCING_AGENT_LICENSE_STATES',
        },
        exsid: {
            field_format: { nocommas: true },
        },
        besid: {
            field_format: { nocommas: true, subtype: 'button' },
            onClick: (props: any) => {
                let { ENV, currentSubmission } = props;
                if (!currentSubmission || !currentSubmission.blocking_external_submission_id) {
                    return null;
                }
                window.open(getBlockingExternalSubmissionUrl(currentSubmission, ENV), '_blank');
            },
            cellDataGetter: (blocking_external_submission_id: any, currentSubmission: any) => {
                let { blocking_external_submission_source } = currentSubmission;
                if (blocking_external_submission_source === EXTERNAL_SOURCES.ARCH) {
                    return `S${blocking_external_submission_id}`;
                }
                return blocking_external_submission_id;
            },
        },
        proimod: {
            field_format: { nocommas: true },
        },
        paiobjid: {
            field_format: { nocommas: true },
        },
        cinid: {
            field_format: { nocommas: true },
        },
        inid: {
            field_format: { nocommas: true },
        },
        ciei: {
            field_format: { nocommas: true },
        },
        atid: {
            name: 'Assigned To',
            nullLabel: 'Unassigned',
            nullable: true,
            constants_choices: 'CLEARANCE_USERS',
            readonly: (rowData: Record<string, any>, extraData: Record<string, any>) => !extraData || !extraData.me || !extraData.me.can_assign_submissions,
        },
        arf: {
            name: 'Research',
            readonly: SUBMISSION_READONLY,
        },
        lsbro: {
            name: 'Last SOV Bot Received',
            cellType: 'datetime',
            field_name: 'last_sov_bot_received_on',
            field_format: { dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S },
        },
        iloro: {
            name: 'Last OFAC Received',
            cellType: 'datetime',
            field_name: 'arch_account_last_ofac_received_on',
            field_format: { dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S },
        },
        last_archlink_ofac_check_at: {
            name: 'OFAC Last Run On',
            type: 'datetime',
            field_name: 'last_archlink_ofac_check_at',
            field_format: { dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S },
            cellDataGetter: (_: any, submission: any) => {
                if (submission.arch_clearance_api_account_status) {
                    return submission.last_archlink_ofac_check_at;
                }
                return submission.arch_account_last_ofac_received_on;
            },
        },
        arch_account_last_ofac_submit_status: {
            name: 'OFAC Run Status',
            field_name: 'arch_account_last_ofac_submit_status',
            cellDataGetter: (_: any, submission: any) => {
                const capitalize = (s: string) => (s ? s.charAt(0).toUpperCase() + s.slice(1).toLowerCase() : s);
                let run_status = submission.arch_account_last_ofac_submit_status;
                if (submission.arch_clearance_api_account_status) {
                    run_status = submission.arch_clearance_api_account_status.toLowerCase() !== 'suspended' ? submission.arch_clearance_api_account_status : '';
                }
                return capitalize(run_status);
            },
        },
        arch_account_last_ofac_submit_by_name: {
            name: 'OFAC Run By',
            field_name: 'arch_account_last_ofac_submit_by_name',
            cellDataGetter: (submit_by: any, submission: any) => {
                if (submission.arch_clearance_api_account_status && submission.arch_clearance_api_account_status.toLowerCase() === 'suspended') {
                    return '';
                }
                return submit_by || submission.arch_clearance_api_account_status ? 'ArchLink' : 'VMAC';
            },
        },
        last_sov_bot_received_on: {
            name: 'SOV Bot Last Run On',
            type: 'datetime',
            field_name: 'last_sov_bot_received_on',
            field_format: { dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S },
        },
        arch_clearance_api_change_on: {
            name: 'ARCH Local Field Change On',
            type: 'datetime',
            field_name: 'arch_clearance_api_change_on',
            field_format: { dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S },
        },
        arch_clearance_api_received_on: {
            name: 'ARCH Response Received On',
            type: 'datetime',
            field_name: 'arch_clearance_api_received_on',
            field_format: { dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S },
        },
        bp: {
            name: 'Bind Propensity',
            rating: true,
        },
        pbs: {
            name: 'BOR Status',
            readonly: true,
        },
        pbsid: {
            w: 100,
            name: 'Pending BOR Incumbent ID',
            field_format: { nocommas: true },
        },
        ibid: {
            name: 'Incumbent Broker',
            type: 'text',
            readonly: true,
            field_name: 'bor_incumbent_broker',
        },
        nbid: {
            name: 'New Broker',
            type: 'text',
            readonly: true,
        },
        sova: {
            name: 'SOV Absent',
            readonly: true,
        },
    },
    brokermatches: {
        n: {
            w: 300,
            field_name: 'name',
            short_name: 'Name',
        },
        e: {
            w: 200,
            field_name: 'email',
            short_name: 'Email',
        },
        c: {
            w: 300,
            field_name: 'parent.parent_company',
            short_name: 'Company',
            cellDataGetter: (parent_company: any) => {
                return (parent_company && parent_company.name) || UNKNOWN;
            },
        },
        ru: {
            w: 250,
            field_name: 'parent',
            short_name: 'Underwriter',
            cellDataGetter: (parent: any) => {
                const requestedUnderwriter = parent.requested_underwriter;
                return requestedUnderwriter ? requestedUnderwriter.full_name : '';
            },
        },
        o: {
            w: 250,
            field_name: 'parent',
            short_name: 'Office',
            cellDataGetter: (parent: any) => {
                return (parent && parent.office) || UNKNOWN;
            },
        },
        sel: SELECT_COL_PROPS,
    },
    brokerofficematches: {
        n: {
            w: 350,
            field_name: 'name',
            short_name: 'Office Name',
        },
        str: {
            w: 350,
            field_name: 'street',
            short_name: 'Street',
        },
        c: {
            w: 200,
            field_name: 'office',
            short_name: 'City',
        },
        sta: {
            w: 80,
            field_name: 'state',
            short_name: 'State',
        },
        zi: {
            w: 100,
            field_name: 'zipcode',
            short_name: 'Zipcode',
        },
        ai: {
            w: 100,
            field_name: 'arch_id',
            short_name: 'Arch ID',
            cellDataGetter: (arch_id: any) => {
                return arch_id ? `P${arch_id}` : '';
            },
        },
        sel: SELECT_OFFICE_COL_PROPS,
    },
    duplicatesubmissions: {
        i: {
            w: 140,
            field_name: 'id',
            short_name: 'ID',
            extraContent: extraDuplicateIdContent,
            cellDataGetter: () => {
                return '';
            },
        },
        sd: {
            w: 90,
            field_name: 'status_display',
            short_name: 'Status',
            extraContent: extraStatusContent,
            cellDataGetter: (val: string, row: any) => {
                // VIKI-8771
                if (row.source === EXTERNAL_SOURCES.VIKI || row.source === EXTERNAL_SOURCES.ARCH) {
                    return row.imported_status_display || row.status_display;
                }
                return row.status_display;
            },
        },
        src: {
            w: 80,
            field_name: 'source',
            short_name: 'Source',
        },
        inc: {
            w: 75,
            field_name: 'inception_date',
            short_name: 'Inc.',
            cellType: 'date',
        },
        exp: {
            w: 75,
            field_name: 'expiration_date',
            short_name: 'Exp.',
            cellType: 'date',
        },
        dtr: {
            w: 150,
            field_name: 'date_received',
            short_name: 'Date/Time',
            cellType: 'datetime',
        },
        dti: {
            w: 50,
            field_name: 'days_till_inception',
            short_name: 'DTI',
        },
        b: {
            w: 150,
            field_name: 'account_agent_name',
            short_name: 'Acct Broker',
        },
        bco: {
            w: 125,
            field_name: 'office_parent_company_short_name',
            short_name: 'Broker Co.',
        },
        bldc: {
            w: 85,
            field_name: 'sov_building_count',
            short_name: '# Bldgs',
        },
        tiv: {
            w: 100,
            field_name: 'sov_total_tiv',
            short_name: 'TIV',
            type: 'number',
            field_format: {
                subtype: 'currency',
                decimals: 0,
            },
        },
        cn: {
            w: 250,
            short_name: 'Client',
            field_name: 'arch_account_name',
            toolTipFn: (row: any) => {
                return row.arch_account_name || '';
            },
        },
        cstreet: {
            w: STREET.w,
            short_name: 'Street',
            field_name: 'arch_account_street',
            toolTipFn: (row: any) => {
                return row.arch_account_street || '';
            },
        },
        ccity: {
            w: CITY.w,
            short_name: 'City',
            field_name: 'arch_account_city',
            toolTipFn: (row: any) => {
                return row.arch_account_city || '';
            },
        },
        cstate: {
            w: STATE.w,
            short_name: 'ST',
            field_name: 'arch_account_state',
        },
        czipcode: {
            w: ZIPCODE.w,
            short_name: 'Zip',
            field_name: 'arch_account_zipcode',
        },
        cfein: {
            w: FEIN.w,
            short_name: 'FEIN',
            field_name: 'arch_account_fein',
        },
        a: {
            w: 85,
            field_name: 'actions',
            name: 'Actions',
            cellType: 'actions',
            actions: [
                {
                    text: 'GO TO',
                    actionFn: 'onGoToDupe',
                },
            ],
        },
        uwbd: {
            w: 100,
            field_name: 'uw_business_division',
            short_name: 'Division',
            constants_choices: 'BUSINESS_DIVISION_CODES',
        },
        uwsd: {
            w: 100,
            field_name: 'uw_sub_division',
            short_name: 'Sub Division',
            constants_choices: 'BUSINESS_SUB_DIVISION_CODES',
        },
    },
    conflicttable: {
        si: {
            w: 130,
            field_name: 'submission_id',
            short_name: 'Sub ID',
            extraContent: extraSubmissionConflictContent,
            cellType: 'actions',
            actions: [
                {
                    text: (rowData: any) => {
                        return setBadgesOnCoflictTableSubmissionId(rowData);
                    },
                    actionFn: 'onGoToDupe',
                },
            ],
        },
        ai: {
            w: 90,
            field_name: 'account_id',
            short_name: 'Acct ID',
            cellType: 'actions',
            actions: [
                {
                    text: (rowData: any) => {
                        return rowData.account_id;
                    },
                    actionFn: 'onGoToAccountId',
                },
            ],
        },
        // src: {
        //     w: 80,
        //     field_name: 'source',
        //     short_name: 'Source',
        // },
        c: {
            w: 90,
            field_name: 'conflict',
            short_name: 'Conflict',
            extraContent: getConflictType,
            cellDataGetter: () => {
                return '';
            },
        },
        rsi: {
            w: 140,
            field_name: 'renewal_of_submission',
            short_name: 'Renewal of SID',
            cellType: 'actions',
            actions: [
                {
                    text: (rowData: any) => {
                        return rowData.renewal_of_submission;
                    },
                    actionFn: 'onGoToRenewal',
                },
            ],
        },
        sd: {
            w: 120,
            field_name: 'status',
            short_name: 'Status',
            extraContent: extraStatusContent,
            cellDataGetter: (val: string, row: any) => {
                // VIKI-8771
                if (row.source === EXTERNAL_SOURCES.VIKI || row.source === EXTERNAL_SOURCES.ARCH) {
                    return row.imported_status_display || row.status;
                }
                return row.status;
            },
            toolTipFn: (row: any) => {
                return row.status || '';
            },
        },
        cn: {
            w: 300,
            short_name: 'Account Name',
            field_name: 'client',
            toolTipFn: (row: any) => {
                return row.client || '';
            },
        },
        cstreet: {
            w: STREET.w,
            short_name: 'Street',
            field_name: 'street',
            toolTipFn: (row: any) => {
                return row.street || '';
            },
        },
        apt: {
            w: 110,
            field_name: 'apt_suite',
            short_name: 'Apt / Suite',
        },
        ccity: {
            w: CITY.w,
            short_name: 'City',
            field_name: 'city',
            toolTipFn: (row: any) => {
                return row.city || '';
            },
        },
        cstate: {
            w: 80,
            short_name: 'State',
            field_name: 'state',
        },
        czipcode: {
            w: ZIPCODE.w,
            short_name: 'Zip',
            field_name: 'zip',
        },
        pas: {
            w: 90,
            field_name: 'pas',
            short_name: 'PAS',
        },
        b: {
            w: 160,
            field_name: 'account_broker',
            short_name: 'Account Broker',
            toolTipFn: (row: any) => {
                return row.account_broker || '';
            },
        },
        bco: {
            w: 160,
            field_name: 'broker_company',
            short_name: 'Broker Office',
            toolTipFn: (row: any) => {
                return row.broker_company || '';
            },
        },
        cfein: {
            w: FEIN.w,
            short_name: 'fein',
            field_name: 'insured_fein',
        },
        effd: {
            w: 140,
            field_name: 'effective_date',
            short_name: 'Effective Date',
            cellType: 'datetime',
            field_format: {
                dateDisplayFormat: DISPLAY_DATE_FORMAT,
                dateStoreFormat: DEFAULT_DATETIME_FORMAT,
            },
        },
        dproc: {
            w: 150,
            field_name: 'date_processed',
            short_name: 'Date Received',
            cellType: 'datetime',
            field_format: {
                dateDisplayFormat: DISPLAY_DATE_FORMAT,
                dateStoreFormat: DEFAULT_DATETIME_FORMAT,
            },
        },
        dti: {
            w: 50,
            field_name: 'dti',
            short_name: 'DTI',
        },
        tiv: {
            w: 100,
            field_name: 'tiv',
            short_name: 'TIV',
            type: 'number',
            field_format: {
                subtype: 'currency',
                decimals: 0,
            },
        },
        bldc: {
            w: 100,
            field_name: 'buildings',
            short_name: 'Buildings',
        },
    },
    clientmatches: {
        n: {
            w: 350,
            field_name: 'name',
            short_name: 'Insured Name',
            toolTipFn: (row: any) => {
                return row.name || '';
            },
            readonly: INSURED_READONLY_CLIENT_MATCHING,
        },
        st: { readonly: INSURED_READONLY_CLIENT_MATCHING, ...STREET },
        c: { readonly: INSURED_READONLY_CLIENT_MATCHING, ...CITY },
        s: { readonly: INSURED_READONLY_CLIENT_MATCHING, ...STATE },
        z: { readonly: INSURED_READONLY_CLIENT_MATCHING, ...ZIPCODE },
        f: { readonly: INSURED_READONLY_CLIENT_MATCHING, ...FEIN },
        ioi: { readonly: INSURED_READONLY_CLIENT_MATCHING, ...IMPORT_OBJECT_ID },
        aid: {
            readonly: INSURED_READONLY_CLIENT_MATCHING,
            field_name: 'arch_id',
            short_name: 'Arch ID',
            urlFn: (rowData: any, extraData: any = {}) => {
                return {
                    target: '_blank',
                    url: `${extraData.ARCH_BASE_URL}BizShell/Web/Source/DomainShell.aspx?DomainID=${getDomainIdForEnv(
                        extraData.env
                    )}&AppID=${getAccountNumberFromString(rowData.arch_id)}&ExternalKey=A${rowData.arch_id}`,
                };
            },
        },
        as: {
            readonly: INSURED_READONLY_CLIENT_MATCHING,
            field_name: 'status_verbose',
            short_name: 'Status',
            w: 100,
        },

        sel: SELECT_COL_PROPS,
        sela: SELECT_ARCH_ACCOUNT_COL_PROPS,
        subs: {
            w: 50,
            field_name: 'submission',
            short_name: 'VIKI SID',
            cellDataGetter: (s: any, arch_account: any) => {
                let { last_bound_submission } = arch_account;
                return last_bound_submission && last_bound_submission.id;
            },
        },
        id: {
            field_name: 'inception_date',
            short_name: 'Inception Date',
            cellDataGetter: (s: any, arch_account: any) => {
                let { last_bound_submission } = arch_account;
                return last_bound_submission && last_bound_submission.inception_date;
            },
        },
        if: {
            field_name: 'in_force',
            short_name: 'In-Force',
            cellDataGetter: (s: any, arch_account: any) => {
                let { last_bound_submission } = arch_account;
                return last_bound_submission && (last_bound_submission.in_force ? 'Yes' : 'No');
            },
        },
        dtr: {
            field_name: 'date_time_received',
            short_name: 'Date/Time Received',
            cellDataGetter: (s: any, arch_account: any) => {
                let { last_bound_submission } = arch_account;
                const date = last_bound_submission && last_bound_submission.date_received;
                return date ? moment(date).format(DISPLAY_DATE_TIME_AM_PM_S) : '';
            },
        },
    },
    producersearchresults: {
        sel: SELECT_ARCH_PRODUCER_COL_PROPS,
        sela: SELECT_ARCH_ACCOUNT_COL_PROPS,
        i: {
            w: 120,
            field_name: 'id',
            short_name: 'ID',
        },
        pid: {
            w: 120,
            field_name: 'arch_id',
            short_name: 'Producer ID',
        },
        n: {
            w: 450,
            field_name: 'name',
            short_name: 'Producer Name',
            toolTipFn: (row: any) => {
                return row.name || '';
            },
        },
        std: {
            w: 120,
            field_name: 'status_display',
            short_name: 'Status',
        },
        st1: {
            w: 200,
            field_name: 'street_address_1',
            short_name: 'Street Address 1',
        },
        st2: {
            w: 200,
            field_name: 'street_address_2',
            short_name: 'Street Address 2',
        },
        s: STATE,
        c: CITY,
        z: ZIPCODE,
        rt: {
            w: 100,
            field_name: 'recaps_to',
            short_name: 'Recaps To',
        },
        cn: {
            w: 220,
            field_name: 'contact_name',
            short_name: 'Contact Name',
        },
        e: {
            w: 200,
            field_name: 'email',
            short_name: 'Email',
        },
        pn: {
            w: 100,
            field_name: 'phone_number',
            short_name: 'Phone Number',
        },
        f: FEIN,
        st: {
            w: 150,
            field_name: 'status',
            short_name: 'Status',
        },
        t: {
            w: 150,
            field_name: 'type',
            short_name: 'Type',
        },
        r: {
            w: 150,
            field_name: 'relationship',
            short_name: 'Relationship',
        },
        an: {
            w: 350,
            field_name: 'alt_names',
            short_name: 'Alternate Names',
            toolTipFn: (row: any) => {
                return row.alt_names || '';
            },
        },
        sd: {
            w: 600,
            field_name: 'subdivisions',
            short_name: 'Subdivisions',
            toolTipFn: (row: any) => {
                return row.subdivisions || '';
            },
            cellDataGetter: (val: string, row: any) => {
                return filterRelevantSubdivisions(row.subdivisions);
            },
        },
        lmd: {
            field_name: 'last_modified',
            short_name: 'Last Modified Data/Time',
            cellDataGetter: (s: any, last_modified: any) => {
                return last_modified ? moment(last_modified).format(DISPLAY_DATE_TIME_AM_PM_S) : '';
            },
        },
    },
    borsubmissionmatches: {
        i: {
            w: 140,
            field_name: 'id',
            short_name: 'ID',
            extraContent: pendingBorExtraDuplicateContent,
            cellDataGetter: () => {
                return '';
            },
        },
        src: {
            w: 80,
            field_name: 'source',
            short_name: 'Source',
        },
        sd: {
            w: 115,
            field_name: 'status_display',
            short_name: 'Status',
            extraContent: extraStatusContent,
        },
        inc: {
            w: 100,
            field_name: 'inception_date',
            short_name: 'Inc.',
            cellType: 'date',
        },
        exp: {
            w: 100,
            field_name: 'expiration_date',
            short_name: 'Exp.',
            cellType: 'date',
        },
        dtr: {
            w: 150,
            field_name: 'date_received',
            short_name: 'Date/Time',
            cellType: 'datetime',
        },
        dti: {
            w: 50,
            field_name: 'days_till_inception',
            short_name: 'DTI',
        },
        rno: {
            w: 150,
            field_name: 'renewal_of',
            short_name: 'Renewal Of',
            extraContent: pendingBorExtraDuplicateContent,
            cellDataGetter: () => {
                return '';
            },
        },
        agnt: {
            w: 150,
            field_name: 'broker_name',
            short_name: 'Agent',
        },
        proc: {
            w: 300,
            field_name: 'producer_company',
            short_name: 'Producer Company',
        },
        cn: {
            w: 350,
            short_name: 'Client',
            field_name: 'arch_account_name',
        },
        a: {
            w: 160,
            field_name: 'actions',
            name: 'Actions',
            cellType: 'actions',
            actions: [
                {
                    text: (rowData: any, extraData: any) => {
                        let action = '';
                        if (rowData.id === extraData.currentSubmission.id || rowData.renewal_of) {
                            action = '';
                        } else {
                            if (extraData.borSubmissions.includes(rowData.id)) {
                                action = 'Remove';
                            } else {
                                action = 'Add';
                            }
                        }
                        return action;
                    },
                    actionFn: 'onMarkPendingBor',
                },
            ],
        },
    },
    accountconflicts: {
        sel: SELECT_ARCH_COL_PROPS,
        an: {
            w: 100,
            field_name: 'accountNumber',
            short_name: 'Account Number',
            urlFn: (rowData: any, extraData: any = {}) =>
                rowData.accountNumber === 'NEW'
                    ? { target: '', url: '' }
                    : {
                          target: '_blank',
                          url: `${extraData.ARCH_BASE_URL}BizShell/Web/Source/DomainShell.aspx?DomainID=2&AppID=${getAccountNumberFromString(
                              rowData.accountNumber
                          )}&ExternalKey=${rowData.accountNumber}`,
                      },
        },
        expdt: {
            w: 125,
            field_name: 'expiration_date',
            short_name: 'Expiration',
        },
        addr: {
            w: 250,
            field_name: 'address1',
            short_name: 'Street',
        },
        addr1: {
            w: 175,
            field_name: 'address2',
            short_name: 'Address (2)',
        },
        c: {
            w: 100,
            field_name: 'city',
            short_name: 'City',
        },
        s: {
            w: 100,
            field_name: 'state',
            short_name: 'State',
        },
        ct: {
            w: 100,
            field_name: 'conflictType',
            short_name: 'Conflict Type',
        },
        st: {
            w: 100,
            field_name: 'accountStatus',
            short_name: 'Status',
        },
        effd: {
            w: 100,
            field_name: 'effectiveDate',
            short_name: 'Effective Date',
            cellType: 'datetime',
            field_format: {
                dateDisplayFormat: DISPLAY_DATE_FORMAT,
                dateStoreFormat: DEFAULT_DATETIME_FORMAT,
            },
        },
        expd: {
            w: 100,
            field_name: 'expirationDate',
            short_name: 'Expiration Date',
            cellType: 'datetime',
            field_format: {
                dateDisplayFormat: DISPLAY_DATE_FORMAT,
                dateStoreFormat: DEFAULT_DATETIME_FORMAT,
            },
        },
        n: {
            w: 300,
            field_name: 'name',
            short_name: 'Name',
        },
        sid: {
            w: 100,
            field_name: 'submissionNumber',
            short_name: 'SID',
            urlFn: (rowData: any, extraData: any = {}) => {
                return {
                    target: '_blank',
                    url: `${extraData.ARCH_BASE_URL}BizShell/Web/Source/DomainShell.aspx?DomainID=1&AppID=${getAccountNumberFromString(
                        rowData.submissionNumber
                    )}`,
                };
            },
        },
        z: {
            w: 100,
            field_name: 'zipcode',
            short_name: 'Zipcode',
        },
    },
    conflictingsubmissions: {
        an: {
            w: 100,
            field_name: 'accountNumber',
            short_name: 'Account Number',
            urlFn: (rowData: any, extraData: any = {}) => {
                return {
                    target: '_blank',
                    url: `${extraData.ARCH_BASE_URL}BizShell/Web/Source/DomainShell.aspx?DomainID=2&AppID=${getAccountNumberFromString(
                        rowData.accountNumber
                    )}&ExternalKey=${rowData.accountNumber}`,
                };
            },
            extraContent: extraSubmissionConflictContent,
        },
        pas: {
            w: 100,
            field_name: 'policyAdminSystem',
            short_name: 'PAS',
        },
        addr: {
            w: 175,
            field_name: 'address1',
            short_name: 'Address',
        },
        addr1: {
            w: 125,
            field_name: 'address2',
            short_name: 'Address (2)',
        },
        c: {
            w: 100,
            field_name: 'city',
            short_name: 'City',
        },
        s: {
            w: 100,
            field_name: 'state',
            short_name: 'State',
        },
        ct: {
            w: 100,
            field_name: 'conflictType',
            short_name: 'Conflict Type',
        },
        effd: {
            w: 100,
            field_name: 'effectiveDate',
            short_name: 'Effective Date',
            cellType: 'datetime',
            field_format: {
                dateDisplayFormat: DISPLAY_DATE_FORMAT,
                dateStoreFormat: DEFAULT_DATETIME_FORMAT,
            },
        },
        expd: {
            w: 100,
            field_name: 'expirationDate',
            short_name: 'Expiration Date',
            cellType: 'datetime',
            field_format: {
                dateDisplayFormat: DISPLAY_DATE_FORMAT,
                dateStoreFormat: DEFAULT_DATETIME_FORMAT,
            },
        },
        n: {
            w: 300,
            field_name: 'name',
            short_name: 'Name',
        },
        sid: {
            w: 100,
            field_name: 'submissionNumber',
            short_name: 'SID',
            urlFn: (rowData: any, extraData: any = {}) => {
                return {
                    target: '_blank',
                    url: `${extraData.ARCH_BASE_URL}BizShell/Web/Source/DomainShell.aspx?DomainID=1&AppID=${getAccountNumberFromString(
                        rowData.submissionNumber
                    )}`,
                };
            },
        },
        z: {
            w: 100,
            field_name: 'zipcode',
            short_name: 'Zipcode',
        },
        div: {
            w: 100,
            field_name: 'division',
            short_name: 'Division',
            constants_choices: 'BUSINESS_DIVISION_CODES',
        },
        sdiv: {
            w: 100,
            field_name: 'subDivision',
            short_name: 'Sub Division',
            // constants_choices: 'BUSINESS_SUB_DIVISION_CODES',
        },
    },
    deltarecords: {
        i: {
            w: 50,
            field_name: 'id',
            short_name: 'ID',
        },
        cn: {
            w: 200,
            field_name: 'change_new',
            short_name: 'New',
        },
        co: {
            w: 200,
            field_name: 'change_old',
            short_name: 'Old',
        },
        cr: {
            w: 160,
            field_name: 'change_reason',
            short_name: 'Data Source',
            constants_choices: 'DELTA_RECORD_SOURCE',
        },
        cf: {
            w: 200,
            field_name: 'change_field',
            short_name: 'Field',
        },
        cs: {
            w: 115,
            field_name: 'changes',
            short_name: 'Changes',
        },
        ovn: {
            w: 200,
            field_name: 'object_verbose_name',
            short_name: 'Object',
        },
        oi: {
            w: 115,
            field_name: 'object_id',
            short_name: 'Object ID',
        },
        hcr: {
            w: 115,
            field_name: 'history_change_reason',
            short_name: 'Change Reason',
        },
        hun: {
            w: 200,
            field_name: 'history_user__name',
            short_name: 'User',
            cellDataGetter: (user: any) => {
                if (!user) {
                    return 'VMAC';
                }
                return user;
            },
        },
        ha: {
            w: 115,
            field_name: 'history_action',
            short_name: 'Action',
        },
        hu: {
            w: 200,
            field_name: 'history_user',
            short_name: 'User',
        },
        hd: {
            w: 130,
            field_name: 'history_date',
            short_name: 'Date',
            cellType: 'datetime',
            field_format: {
                dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S,
                dateStoreFormat: DEFAULT_DATETIME_FORMAT,
            },
        },
        had: {
            w: 130,
            field_name: 'history_action_display',
            short_name: 'Action',
        },
        hcrd: {
            w: 130,
            field_name: 'history_change_reason_display',
            short_name: 'Data Source',
        },
    },
    imagerightlogs: {
        i: {
            w: 50,
            field_name: 'id',
            short_name: 'ID',
        },
        c: {
            w: 160,
            field_name: 'created',
            short_name: 'Created',
            cellType: 'datetime',
            field_format: { dateDisplayFormat: DISPLAY_DATE_TIME_AM_PM_S },
        },
        ad: {
            w: 115,
            field_name: 'action_display',
            short_name: 'Action',
        },
        sd: {
            w: 115,
            field_name: 'status_display',
            short_name: 'Status',
        },
        sid: {
            w: 130,
            field_name: 'submission_id',
            short_name: 'Submission Id',
        },
        did: {
            w: 240,
            field_name: 'document_ids',
            short_name: 'Document Ids',
        },
    },
    alternativenames: {
        t: {
            w: 175,
            field_name: 'type_code',
            short_name: 'Type',
            readonly: false,
        },

        an: {
            w: 125,
            field_name: 'name',
            short_name: 'Alternative Name',
            readonly: false,
            toolTipFn: (row: any) => {
                let note = null;
                switch (row.action) {
                    case 'Pending':
                        note = 'Not sent to Archlink yet';
                        break;
                    case 'Ofac':
                        note = 'Account status is OFAC Pending';
                        break;
                    case 'Error':
                        note = `Action: ${row.processing_info.last_action}`;
                        break;
                    case 'Conflict':
                        note = 'Unable to auto-resolve the conflict. Refer to AL';
                        break;
                    default:
                        note = null;
                        break;
                }
                return note;
            },
        },
        ra: {
            w: 100,
            field_name: 'reuseAddress',
            short_name: 'Reuse Address?',
            readonly: false,
        },
        a1: {
            w: 150,
            field_name: 'address1',
            short_name: 'Address 1',
            readonly: false,
        },
        a2: {
            w: 100,
            field_name: 'address2',
            short_name: 'Address 2',
            readonly: false,
        },
        c: {
            w: 75,
            field_name: 'city',
            short_name: 'City',
            readonly: false,
        },
        s: {
            w: 65,
            field_name: 'state',
            short_name: 'State',
            readonly: false,
        },
        z: {
            w: 55,
            field_name: 'zipcode',
            short_name: 'Zip',
            readonly: false,
        },
        ac: {
            w: 100,
            field_name: 'action',
            short_name: 'Action',
            readonly: false,
            extraContent: getAlternateNameActionBadges,
            cellDataGetter: (_value, _rowData, _field, props) => {
                return '';
            },
        },
    },
};
